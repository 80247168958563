var Aneox;
(function (Aneox) {
  'use strict';

  var Gallery = (function () {

    var bodyElement;
    var galleryElement;
    var galleryImagesElement;
    var galleryControlsItemsElement;
    
    var isOpen;
    var gallery;
    var activeIndex;
    var hammertime;

    function Gallery() {
      var self = this;

      bodyElement = $('body');
      galleryElement = $('#gallery');
      galleryImagesElement = $('#gallery-images');
      galleryControlsItemsElement = $('#gallery-controls-items');
    }

    Gallery.prototype.show = function (galleryName) {
      $('.header-button-menu').hide();
      var closeButton = $('.header-button-close-gallery');
      closeButton.show();
      if (galleryName === 'people') {
        closeButton.children('img:eq(0)').hide();
        closeButton.children('img:eq(1)').show();
      } else {
        closeButton.children('img:eq(0)').show();
        closeButton.children('img:eq(1)').hide();
      }
      gallery = _.find(Aneox.Data.Data.galleries, { id: galleryName });

      
      if (!gallery || isOpen) return;
      isOpen = true;
      
      bodyElement.addClass('noscroll');
      galleryElement.addClass('active');

      galleryElement.css('background-color', gallery.bgColor);
      
      galleryImagesElement.empty();
      galleryControlsItemsElement.empty();
      
      _.forEach(gallery.images, function (image) {
        galleryImagesElement.append('<div class="image background-cover" style="background-image: url(' + image + ')"></div>');
        galleryControlsItemsElement.append('<li></li>')
      });
      
      activeIndex = 0;
      $('.image', galleryImagesElement).eq(activeIndex).addClass('active');
      $('li', galleryControlsItemsElement).eq(activeIndex).addClass('active');
      
      hammertime = new Hammer(document.body);
      hammertime.get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL, velocity: 0.33 });
      hammertime.on('swipeleft', this.next);
      hammertime.on('swiperight', this.previous);
    };

    Gallery.prototype.close = function () {
      if (!isOpen) return;

      $('.header-button-close').hide();
      $('.header-button-menu').show();

      isOpen = false;
      
      bodyElement.removeClass('noscroll');
      galleryElement.removeClass('active').removeClass('ltr-in').removeClass('ltr-out').removeClass('rtl-in').removeClass('rtl-out');
      
      hammertime.off('swipeleft');
      hammertime.off('swiperight');
      hammertime.destroy();
      hammertime = undefined;
    };

    Gallery.prototype.previous = function () {
      if (!isOpen || activeIndex === 0) return;
      
      $('.image', galleryImagesElement).removeClass('active previous next');
      $('li', galleryControlsItemsElement).removeClass('active');

      $('.image', galleryImagesElement).eq(activeIndex).addClass('next');
      
      activeIndex -= 1;
      if (activeIndex === 0) {
        $('#gallery-previous').attr('disabled', 'disabled');
      } else {
        $('#gallery-previous').removeAttr('disabled');
      }
      if (activeIndex === gallery.images.length - 1) {
        $('#gallery-next').attr('disabled', 'disabled');
      } else {
        $('#gallery-next').removeAttr('disabled');
      }
      
      $('.image', galleryImagesElement).eq(activeIndex).addClass('active');
      $('li', galleryControlsItemsElement).eq(activeIndex).addClass('active');

      if (activeIndex - 1 >= 0) {
        $('.image', galleryImagesElement).eq(activeIndex - 1).addClass('previous');
      }
    };

    Gallery.prototype.next = function () {
      if (!isOpen || activeIndex === gallery.images.length - 1) return;

      $('.image', galleryImagesElement).removeClass('active previous next');
      $('li', galleryControlsItemsElement).removeClass('active');
      
      $('.image', galleryImagesElement).eq(activeIndex).addClass('previous');
      
      activeIndex += 1;
      if (activeIndex === 0) {
        $('#gallery-previous').attr('disabled', 'disabled');
      } else {
        $('#gallery-previous').removeAttr('disabled');
      }
      if (activeIndex === gallery.images.length - 1) {
        $('#gallery-next').attr('disabled', 'disabled');
      } else {
        $('#gallery-next').removeAttr('disabled');
      }
      
      $('.image', galleryImagesElement).eq(activeIndex).addClass('active');
      $('li', galleryControlsItemsElement).eq(activeIndex).addClass('active');

      $('.image', galleryImagesElement).eq(activeIndex + 1).addClass('next');
    };

    MicroEvent.mixin(Gallery);

    return Gallery;
  })();

  Aneox.Gallery = Gallery;
})(Aneox || (Aneox = {}));
var Aneox;
(function (Aneox) {
  'use strict';

  var App = (function () {

    var menuOverlay;
    var menuOverlayActive;
    var canShowMenuOverlay;

    function App(fonts, locale, router, analytics, animations, gallery, wines, importerContinent) {
      this.fonts = fonts;
      this.locale = locale;
      this.router = router;
      this.analytics = analytics;
      this.animations = animations;
      this.gallery = gallery;
      this.wines = wines;
      this.importerContinent = importerContinent;

      this.pageChangeFromScroll = false;

      var viewportWidth = $(window).width();
      var viewportHeight = $(window).height();
      var articles, previousScrollSectionHint, scrollSectionHint, scrollSectionHintNext, scrollSectionHintBackground;
      var endScrollMode, endScrollStart, endScrollOffset;
      
      // Quick and dirty desktop/mobile check
      if (screen && screen.availWidth >= 1024) window.location.href = 'http://www.henri-boillot.com';
      
      
        
      var now = new Date();
      now.setFullYear(now.getFullYear() - 18);
      var nowText = now.toISOString().substring(0, 10).split('-');
      $('#age-check-date').text(nowText[2] + '/' + nowText[1] + '/' + nowText[0]);
      
      var self = this;

      if ($.cookie('agecheck') === 'true') {
        $('#age-check').remove();
        $('html').removeClass('noscroll');
        self.analytics.startAnalytics();
      }

      menuOverlay = $('#menu-overlay');

      FastClick.attach(document.body);

      function updateHeader(sectionId) {
        if (sectionId === 'people') {
          $('.header').css('background-color', '#0d0d0d');
          $('.header-button-menu > img:eq(0)').hide();
          $('.header-button-menu > img:eq(1)').show();
        } else if (sectionId === 'wines') {
          $('.header').css('background-color', 'black');
          $('.header-button-menu > img:eq(0)').hide();
          $('.header-button-menu > img:eq(1)').show();
        } else {
          $('.header').css('background-color', 'white');
          $('.header-button-menu > img:eq(1)').hide();
          $('.header-button-menu > img:eq(0)').show();
        }
        if (sectionId === 'home') {
          if (self.pageChangeFromScroll) {
            $('.header').slideUp(500);
          } else {
            $('.header').hide();
          }
        } else if (!$('.header').is(':visible')) {
          $('.header').slideDown(500);
        }
      }

      this.router.bind('pageChange', function (path, node, nodeLocale) {
        self.locale.setLocale(nodeLocale.locale);

        switch (node.type) {
          case 'wine-sheet':
            self.wines.openPage(node.id, self);
            break;
          case 'vintage-sheet':
            self.wines.openVintagePage(node.id, self);
            break;
          case 'legal':
            self.showLegal();
          case 'basic-page':

          /* $('html, body').animate({
            scrollTop: $('#' + node.id).offset().top
        }, 800, function() {
          updateHeader(node.id);
        }); */

            $('html, body').scrollTop($('#' + node.id).offset().top);
            updateHeader(node.id);
          default:
            canShowMenuOverlay = true;

            var updatePage = function(sectionId) {

              var section = document.getElementById(sectionId);
              var rect = section.getBoundingClientRect();
              if (rect.top <= viewportHeight/2 && rect.bottom >= viewportHeight/2) {
                var newNode = self.router.getNode(sectionId);
                var newNodeLocale = newNode.locales.filter(function(locale) {
                  return locale.locale === nodeLocale.locale;
                })[0];
                var nodePath = self.router.getNodePath(sectionId, locale.getLocale());
                window.history.replaceState({}, newNodeLocale.title, nodePath);

                $('.menu-list a').removeClass('active');
                $('.menu-list a[router-href=' + sectionId + ']').addClass('active');
                updateHeader(sectionId);

                self.pageChangeFromScroll = true;
                self.router.processPath(window.location, newNode, newNodeLocale);
                return false;
              }
            };

            var sectionIds = [
              'home',
              'domain',
              'people',
              'history',
              'values',
              'wines',
              'contact'
            ];

            $(window).on('scroll', _.debounce(function (event) {
              _.forEach(sectionIds, updatePage);
            }, 100));
    
        }

        setTimeout(function () { $('#preloader').remove(); }, 1000);

      });

      this.locale.bind('localeChange', function (locale) {


        $('[router-locale]').removeClass('active')
            .filter('[router-locale=' + locale + ']').addClass('active');

        self.wines.setupList('domaine', 'white', locale);
        self.wines.setupList('domaine', 'red', locale);
        self.wines.setupList('maison', 'white', locale);
        self.wines.setupList('maison', 'red', locale);

      });
      
      this.router.init(true, locale.getLocale(), this);

      self.wines.setupList('domaine', 'white', this.locale.getLocale());
      self.wines.setupList('domaine', 'red', this.locale.getLocale());
      self.wines.setupList('maison', 'white', this.locale.getLocale());
      self.wines.setupList('maison', 'red', this.locale.getLocale());

      $('[router-locale]').removeClass('active')
            .filter('[router-locale=' + this.locale.getLocale() + ']').addClass('active');

      // $('#gallery').on('scroll touchmove mousewheel', function (event) {
      //   return event.preventDefault();
      // });
    }

    App.prototype.scrollDown = function() {
      var scrollDistance = $(window).height();
      animatedScrollTo(
        document.body,
        $('html, body').scrollTop() + scrollDistance,
        500
      );
    };

    App.prototype.showMenu = function () {
      $('#menu').fadeIn(500, function() {
        $('html').addClass('noscroll');
      });
    };

    App.prototype.hideMenu = function () {
      $('html').removeClass('noscroll');
      $('#menu').fadeOut(500);
    };

    App.prototype.showLegal = function () {
      $('html').addClass('noscroll');
      $('#legal').fadeIn(500);
    };

    App.prototype.hideLegal = function () {
      if ($('#menu').css('display') === 'none') {
        $('html').removeClass('noscroll');
      }
      this.router.setPath('');
      $('#legal').fadeOut(500);
    };

    App.prototype.ageCheckResult = function (result, analytics) {
      if (!result) return;
      
      $.cookie('agecheck', 'true');
      $('#age-check').addClass('discarded');
      analytics.startAnalytics();
      

      if ($('#menu').css('display') === 'none' && 
          $('#wine-sheet').css('display') === 'none' &&
          $('#vintage-sheet').css('display') === 'none' &&
          $('#legal').css('display') === 'none') {
        $('html').removeClass('noscroll');
      }
      
      setTimeout(function () { 
        $('#age-check').remove(); 
      }, 500);
    }

    App.prototype.openWinePage = function() {
      $('html').addClass('noscroll');
      $('#wine-sheet').show();
    };

    App.prototype.openVintagePage = function() {
      $('.header-button-menu').hide();
      $('.header-button-close-winesheet').css('display', 'inline-block');
      $('html').addClass('noscroll');
      $('#vintage-sheet').show();
    };

    return App;
  })();

  Aneox.App = App;
})(Aneox || (Aneox = {}));
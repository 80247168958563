var Aneox;
(function (Aneox) {
  'use strict';

  var ImporterContinent = (function () {

    var importerContinentElement;
    var importerContinentListElement;
    var importerCountryListElement;
    var nameElement;

    var continent;
    var continentLocale;

    function ImporterContinent(locale) {
      this.locale = locale;

      importerContinentElement = $('#importer-continent');
      importerCountryListElement = $('#importer-country-list');
      importerContinentListElement = $('#importer-continent-list');
      nameElement = $('#importer-continent-name');
    }

    function appendImporter(importer, countryLocale) {
      importerContinentListElement.append(
        '<div class="importer">' +
        (importer.name ? '<p class="importer-name">' + importer.name + '</p>' : '') +
        (importer.address ? '<p class="importer-address">' + importer.address + '</p>' : '') +
        (countryLocale ? '<p class="importer-country">' + countryLocale.name + '</p>' : '') +
        '<div class="importer-contact">' +
        (importer.phone ? '<p class="importer-phone">tel. : <a href="tel:' + importer.phone + '">' + importer.phone + '</a></p>' : '') +
        (importer.phoneBis ? '<p class="importer-phone">tel. : <a href="tel:' + importer.phoneBis + '">' + importer.phoneBis + '</a></p>' : '') +
        (importer.fax ? '<p class="importer-fax">fax. : <a href="tel:' + importer.fax + '">' + importer.fax + '</a></p>' : '') +
        (importer.web ? '<p class="importer-web">site : <a href="' + importer.web + '" target="_blank">' + importer.web + '</a></p>' : '') +
        (importer.mail ? '<p class="importer-mail">email : <a href="mailto:' + importer.mail + '">' + importer.mail + '</a></p>' : '') +
        '</div>' +
        '</div>'
        );
    }

    ImporterContinent.prototype.show = function (continentId) {
      var locale = this.locale.getLocale();

      continent = _.find(Aneox.Data.Data.importerContinents, { id: continentId });
      continentLocale = _.find(continent.locales, { locale: locale });

      if (!continent || !continentLocale) return;

      $('html,body').addClass('fullheight');
      $('body').addClass('noscroll');

      nameElement.html(continentLocale.name);

      var importers = _.filter(Aneox.Data.Data.importers, { continentId: continentId });
      var countries = [];

      importerContinentListElement.empty();
      importerContinentListElement.scrollTop(0);
      _.forEach(importers, function (importer) {
        var country = _.find(Aneox.Data.Data.importerCountries, { id: importer.countryId });
        var countryLocale = _.find(country.locales, { locale: locale });
        if (!_.contains(countries, country)) countries.push(country);

        appendImporter(importer, countryLocale);
      });

      $('li', importerCountryListElement).not(':first').remove();
      _.forEach(countries, function (country) {
        var countryLocale = _.find(country.locales, { locale: locale });
        importerCountryListElement.append('<li onclick="app.importerContinent.toggleFilters(\'' + country.id + '\')">' + countryLocale.name + '</li>')
      });
    };

    ImporterContinent.prototype.toggleFilters = function (countryId) {
      if (importerCountryListElement.hasClass('open')) {
        importerCountryListElement.removeClass('open');
      } else {
        importerCountryListElement.addClass('open');
        return;
      }

      var locale = this.locale.getLocale();

      $('li', importerCountryListElement).removeClass('active');
      if (countryId) {
        $(event.target).addClass('active');

        var importers = _.filter(Aneox.Data.Data.importers, { countryId: countryId });

        importerContinentListElement.empty();
        importerContinentListElement.scrollTop(0);
        _.forEach(importers, function (importer) {
          var country = _.find(Aneox.Data.Data.importerCountries, { id: importer.countryId });
          var countryLocale = _.find(country.locales, { locale: locale });
          appendImporter(importer, countryLocale);
        });
      } else {
        $('li:first', importerCountryListElement).addClass('active');

        var importers = _.filter(Aneox.Data.Data.importers, { continentId: continent.id });

        importerContinentListElement.empty();
        importerContinentListElement.scrollTop(0);
        _.forEach(importers, function (importer) {
          var country = _.find(Aneox.Data.Data.importerCountries, { id: importer.countryId });
          var countryLocale = _.find(country.locales, { locale: locale });
          appendImporter(importer, countryLocale);
        });
      }
    };

    MicroEvent.mixin(ImporterContinent);

    return ImporterContinent;
  })();

  Aneox.ImporterContinent = ImporterContinent;
})(Aneox || (Aneox = {}));
var Aneox;
(function (Aneox) {
  'use strict';

  var Animations = (function () {

    function Animations() {
      var self = this;

      
    }
    
    MicroEvent.mixin(Animations);

    return Animations;
  })();

  Aneox.Animations = Animations;
})(Aneox || (Aneox = {}));
var Aneox;
(function (Aneox) {
  'use strict';

  var Wines = (function () {

    var self;

    var wine;
    var wineLocale;

    function Wines(locale, router) {
      self = this;
      self.router = router;

      this.router.bind('pageChange', function(path, node, nodeLocale) {
        self.locale = nodeLocale;
        self.texts = _.find(Aneox.Data.Data.wineText, { locale: self.locale.locale });
      });

      $('.wine-millesime-select').change(function() {
        var millesime = _.find(wineLocale.millesime, { year: '' + this.value });
        updateMillesime(millesime);
      });
    }

    Wines.prototype.setupList = function(list, type, locale) {
      var listElement = $('#wines-list-' + list + '-' + type);
      listElement.empty();
      var wines = Aneox.Data.Data.wine.sort(function(wine1, wine2) {
        var order1 = parseInt(wine1.order);
        var order2 = parseInt(wine2.order);
        if (order1 < order2) {
          return -1;
        } else if (order1 > order2) {
          return 1;
        } else {
          return 0;
        }
      });
      _.each(_.filter(wines, {
        type: type,
        list: list
      }), function(wine) {
        listElement.append('<li>' +
          '<a href="' + self.router.getNodePath(wine.id, locale) + '">'
            + wine.name + ' ' + (wine.name2 || '') + '</a>' +
        '</li>');
      });
    };

    Wines.prototype.openPage = function(nodeId, app) {
      wine = _.find(Aneox.Data.Data.wine, { id: nodeId });
      wineLocale = _.find(wine.locale, { locale: self.locale.locale });
      if (!_.isArray(wineLocale.millesime)) {
        wineLocale.millesime = [wineLocale.millesime];
      }
      replaceContent(wine, wineLocale);
      app.openWinePage();
    };

    function replaceContent(wine, wineLocale) {
      $('.header-button-menu').hide();
      $('.header-button-close-winesheet').css('display', 'inline-block');
      $('#wine-sheet').append('<div class="overflow-hidden">' + 
        '<img class="wine-sheet-img" src="' + wine.photo + '">' +
      '</div>');
      $('.wine-sheet-value:not(.wine-sheet-millesime-value)')
        .text('').hide()
        .prev().hide();
      $('#wine-name2').text('');
      wine = _.merge({}, wine, wineLocale);
      _.each(wine, function(value, key) {
        var valueText;
        if (['locale', 'millesime', 'photo'].indexOf(key) > -1) return;
        if (_.isArray(value)) {
          valueText = value.slice(0, -1).join(', ') +
            ' ' + self.texts.et + ' ' + _.last(value);
        } else {
          valueText = value;
        }
        $('#wine-' + key)
          .text(valueText).show()
          .prev().show();
      });

      updateMillesime(wineLocale.millesime[0]);

      // set up millésime dropdown list
      var dropdown = $('.wine-millesime-select').empty();
      _.each(wineLocale.millesime, function(millesime) {
        millesime = millesime.year;
        dropdown.append('<option value="' + millesime + '">' +
          self.texts.millesime + ' ' + millesime +
        '</option>');
      });
      
      
      _.each(wineLocale.millesime, function(millesime) {
        millesime = millesime.year;
        dropdown.append('<li class="wine-millesime-select-option"' +
          'data-millesime="' + millesime + '">' + self.texts.millesime + ' ' +
          millesime + '</li>');
      });
    }

    function updateMapDetail(e, panzoom, scale) {
      if(scale >= 4){
        TweenMax.to("#noms, #nom:not(#pointeurs #nom)", .5, {opacity:1, display: "block"}); 
      } else {
        TweenMax.to("#noms, #nom:not(#pointeurs #nom)", .5, {opacity:0, display: "none"});
      }
    }

    function updateMillesime(millesime) {
      $('.wine-sheet-millesime-value')
        .text('')
        .hide()
        .prev().hide();
      _.each(millesime, function(value, key) {
        if (key === 'tasting') {
          _.each(value, function(value, key) {
            $('#wine-' + key)
              .text(value).show()
              .prev().show();
          });
        } else if(key === 'village'){

          var villageString = '';
          var villageIndex = 0;
          var numberOfVillages = millesime.village.length;

          if(_.isArray(value)){
            _.each(value, function(village, key){
              villageString += (villageIndex == 0 )? village : ', '+village;
              villageIndex++;
            });
          }else{
            villageString = value;
          }

          $('#wine-' + key)
            .text(villageString).show()
            .prev().show();
        } 
        else {
          if(value.length > 0){
            $('#wine-' + key)
            .text(value).show()
            .prev().show();
          }
        }
      });

      // set up download link
      $('#wine-sheet-download').attr('href',
        'pdf.php?wine_id=' + wine.id +
        '&locale=' + self.locale.locale +
        '&vintage=' + millesime.year);
    }

    Wines.prototype.openVintagePage = function(nodeId, app) {
      var year = nodeId.slice(-4);
      var vintage = _.find(Aneox.Data.Data.vintage, { year: year });
      var vintageLocale = _.find(vintage.locale, { locale: self.locale.locale });

      $('#vintage-sheet-year').text(vintage.year);
      $('#vintage-sheet-text').html(vintageLocale.text);
      $('#vintage-sheet-download-link').attr('href', vintageLocale.pdf);
      app.openVintagePage();
    };
    
    MicroEvent.mixin(Wines);

    return Wines;
  })();

  Aneox.Wines = Wines;
})(Aneox || (Aneox = {}));